import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Menu from 'components/LayoutComponents/Virtual/Menu'
import Agenda from 'components/LayoutComponents/Virtual/Agenda'
import Speakers from 'components/LayoutComponents/Virtual/Speakers'
// import Footer from 'components/LayoutComponents/Footer'
// import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import Chat from 'components/LayoutComponents/Virtual/Chat'
import Briefcase from 'components/LayoutComponents/Virtual/Briefcase'
import Leads from 'components/LayoutComponents/Virtual/Leads'
// import ThreeDimensionalModel from 'components/LayoutComponents/Virtual/ThreeDimensionalModel'
// import Meetings from 'components/LayoutComponents/Virtual/Meetings'
import AllMeetings from 'components/LayoutComponents/Virtual/AllMeetings'
// import Directory from 'components/LayoutComponents/Virtual/Directory'
import AIPhotobooth from 'components/LayoutComponents/Virtual/AIPhotobooth'
import ToonPhotobooth from 'components/LayoutComponents/Virtual/ToonPhotobooth'
import Photobooth from 'components/LayoutComponents/Virtual/Photobooth'
import SocialWall from 'components/LayoutComponents/Virtual/SocialWall'
import GamingModel from 'components/LayoutComponents/Virtual/Gaming'
import LeaderBoard from 'components/LayoutComponents/Virtual/LeaderBoard'
import EShop from 'components/LayoutComponents/Virtual/EShop'
import VideoPlaylist from 'components/LayoutComponents/Virtual/VideoPlaylist'
import TickerNotification from 'components/LayoutComponents/Virtual/TickerNotification'
// import MessageBox from 'components/LayoutComponents/Virtual/MessageBox'
// import MessageBoxTicker from 'components/LayoutComponents/Virtual/MessageBoxTicker'
import EditProfile from  'components/LayoutComponents/Virtual/EditProfile'
// import TutorialVideo from 'components/LayoutComponents/Virtual/TutorialVideo'
// import StallDesigner from  'components/LayoutComponents/Virtual/StallDesigner'
import FullScreenButton from 'components/LayoutComponents/Virtual/FullScreenButton'
import ImagePop from 'components/LayoutComponents/Virtual/ImagePop'
import VideoDemand from 'components/LayoutComponents/Virtual/VideoDemand'
import LobbyMusic from 'components/LayoutComponents/Virtual/LobbyMusic'
// import RatingBox from 'components/LayoutComponents/Virtual/RatingBox'
// import ViewedStalls from 'components/LayoutComponents/Virtual/ViewedStalls'
// import PointRedeem from 'components/LayoutComponents/Virtual/PointRedeem'
import Contest from 'components/LayoutComponents/Virtual/Contest'
// import Notes from 'components/LayoutComponents/Virtual/Notes'
// import EntranceVideo from 'components/LayoutComponents/Virtual/EntranceVideo'
import LandscapeOnly from 'components/LayoutComponents/Virtual/LandscapeOnly'
import { Beforeunload } from 'react-beforeunload'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import WhereBy from 'components/LayoutComponents/Virtual/WhereBy'
import {isMobile} from 'react-device-detect';
import './mainstyle.less'

const mapStateToProps = ({ virtualSettings, virtualUser }) => ({
  isVirtualMenuTop: virtualSettings.isVirtualMenuTop,
  isStreamingEvent: virtualSettings.isStreamingEvent,
  isEntranceVideoVisible: virtualUser.isEntranceVideoVisible,
  isEntranceVideoVisible2: virtualUser.isEntranceVideoVisible2
})

@withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  exitPlatform = event => {
    console.log('checking exit platform')
    console.log(event)
    event.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/FAKE_LOGOUT',
    })
  }

  render() {
    const { children, isVirtualMenuTop, isStreamingEvent} = this.props
    const finalLayout = (
      <Layout
        className={classNames({
          settings__menuTop: isVirtualMenuTop,
        })}
      >
        <Menu />
        <Chat />
        <Briefcase />
        <Leads />
        <Photobooth />
        <AIPhotobooth />
        {/* <TreasureHunt /> */}
        <Speakers />
        <ImagePop />
        <VideoDemand />
        <SocialWall />
        <GamingModel />
        <LeaderBoard />
        <EShop />
        <FullScreenButton />
        <WhereBy />
        {/* <EntranceVideo /> */}
        {/* {isEntranceVideoVisible2 ? <EntranceVideo2 /> : ""} */}
        <LobbyMusic />
        <ToonPhotobooth />
        <VideoPlaylist />
        {/* <RatingBox /> */}
        {/* <ViewedStalls /> */}
        {/* <Notes /> */}
        {/* <MessageBox /> */}
        {/* <QuestionBox /> */}
        <EditProfile />
        {/* <ThreeDimensionalModel /> */}
        {/* <TutorialVideo /> */}
        {/* <StallDesigner /> */}
        <Contest />
        {/* <PointRedeem /> */}
        {/* <Directory /> */}
        {/* <Meetings /> */}
        <AllMeetings />
        <Agenda />
        <Layout>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            {/* <Breadcrumbs /> */}
            <div
              // className="utils__content"
              style={{ padding: '0px' }}
            >
              {children}
            </div>
          </Layout.Content>
          {/* <Layout.Footer>
            <Footer />
          </Layout.Footer> */}
        </Layout>
        {/* <MessageBoxTicker /> */}
        <TickerNotification />
      </Layout>
    )

    // let isSafari = false
    // const navUserAgent = navigator.userAgent
    // console.log('seeing useragent')
    // console.log(navUserAgent)
    // if (/apple/i.test(navigator.vendor)) {
    //   isSafari = true
    // }
    return (
      <Beforeunload onBeforeunload={this.exitPlatform}>
        <DeviceOrientation lockOrientation="landscape">
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="landscape" alwaysRender={false}>
            {finalLayout}
          </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
            {/* {isSafari || isStreamingEvent ? finalLayout : <LandscapeOnly />} */}
            {!isMobile || isStreamingEvent ? finalLayout : <LandscapeOnly />}
          </Orientation>
        </DeviceOrientation>
      </Beforeunload>
    )
  }
}

export default MainLayout
