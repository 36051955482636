import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined,UploadOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/toonphotobooth/actions'
import { connect } from 'react-redux'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus */
@connect(mapStateToProps)
class Selection extends React.Component {

  captureImage = () => {
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 2,
      },
    })
  }

  uploadPhotoScreen = () => {
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 3,
      },
    })
  }

  render() {
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center selectionScreen fullHeightScreen">
          <Col span={11} className="text-center">
            <Button type="primary" shape="circle" icon={<UploadOutlined />} size="large" onClick={this.uploadPhotoScreen} />
            <br />
            <br />
            <h3 className="white-text">Upload Image</h3>
          </Col>
          <Col span={2} className="text-center">
            <h2 className="white-text">OR</h2>
          </Col>
          <Col span={11} className="text-center">
            <Button type="primary" shape="circle" icon={<CameraOutlined />} size="large" onClick={this.captureImage} />
            <br />
            <br />
            <h3 className="white-text">Click Photo</h3>
          </Col>
          
          {/* <Col span={12} className="text-center">
            
          </Col> */}
        </Row>
      </>
    )
  }
}

export default Selection
