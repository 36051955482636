import React from 'react'
import { 
  Row, 
  Col, 
  Button 
} from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/toonphotobooth/actions'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus */
@connect(mapStateToProps)
class StartScreen extends React.Component {

  startPhotobooth = () => {
    const {
      dispatch
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 1,
      },
    })
  }

  render() {
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center fullHeightScreen">
          <Col span={7} />
          <Col span={10} className="text-center">
            <img src="resources/images/toonbooth.png" alt="toon sample" style={{width: "100%"}} />
            <br />
            <br />
            <br />
            <Button type="primary" onClick={this.startPhotobooth}>Start Photobooth</Button>
          </Col>
          <Col span={7} />
        </Row>
        {/* <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            <h1 className="intro-heading">Welcome to Toon Photobooth</h1>
          </Col>
        </Row> */}
      </>
    )
  }
}

export default StartScreen
