import React from 'react'
import { Row, Col, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/toonphotobooth/actions'
import Base64Downloader from 'react-base64-downloader'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })

@connect(mapStateToProps)
class Download extends React.Component {
  goBack = () => {
    const {dispatch, toonphotobooth: {currentStep}} = this.props
    dispatch({
      type: actions.SET_STATE,
      payload:{
        currentStep: currentStep - 1,
        toonifiedImage: '',
        imageProcessing: false
      }
    })
  }

  resetPhotobooth = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 0,
        toonifiedImage: '',
        imageSrc: '',
        webcamLoading: false,
        imageProcessing: false
      },
    })
  }
  // downloadFile = () => {
  //   const {photobooth: {toonifiedImage}} = this.props

  // }

  render() {
    const {
      toonphotobooth: { toonifiedImage,imageProcessing },
    } = this.props
    return (
      <>
        <Row className="fullHeightScreen">
          <Col span={16} className="text-left">
            {imageProcessing ? 
              <img src="resources/images/processingloading.gif" alt="loader" style={{ width: '100%', borderRadius: '20px 0 0 20px' }} />
              : 
              <img src={toonifiedImage} alt="newImage" style={{ width: '100%', borderRadius: '20px 0 0 20px' }} />
            }
            
          </Col>
          <Col span={8} className="text-center">
            <Row className="fullHeightScreen" justify="space-around" align="middle">
              <Col span={24} className="text-center downloadArea" style={{padding: '10px'}}>
                <h3 className="white-text">Looking Great !!!</h3>
                <h5 className="white-text">Download your photo</h5>
                <br />
                <Base64Downloader 
                  base64={toonifiedImage} 
                  downloadName="ToonifiedPhotobooth"
                  stye={{ backgroundColor: "#007db6"}}
                >
                  <Button size="large" className="actualDownloadButton">
                    <DownloadOutlined />
                  </Button>
                </Base64Downloader>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default Download
