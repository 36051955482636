import { 
  all, 
  call, 
  takeEvery, 
  // put
  // select 
} from 'redux-saga/effects'
import { uploadBase64ImageforToonify } from 'services/virtual/general'
import { message } from 'antd'
import { store as reduxStore } from 'index'
import actions from './actions'

export function* UPLOAD_IMAGE(data) {
  try {
    // const state = yield select()
    // const {
    //   virtualUser: { accessToken },
    // } = state
    const result = yield call(uploadBase64ImageforToonify, { imageData: data.payload.imageString })

    if(result.status === 200)
    {

      const canvas = document.createElement('canvas')
      canvas.id = 'finalImage'
      canvas.width = 600
      canvas.height = 600
      const ctx = canvas.getContext('2d')

      const backgroundImage = new Image()
      backgroundImage.src = result.data.fileUrl
      backgroundImage.setAttribute('crossorigin', 'anonymous')
      

      const image = new Image()
      image.src = 'resources/images/toonoverlay.png'
      backgroundImage.onload = () => {
        ctx.drawImage(backgroundImage, 0, 0)
        ctx.drawImage(image, 0, 0)
        const finalImage = canvas.toDataURL()
        reduxStore.dispatch({
          type: actions.SET_STATE,
          payload: {
            toonifiedImage: finalImage,
            imageProcessing: false
          },
        })
      }



      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     toonifiedImage: result.data.fileUrl,
      //     imageProcessing: false
      //   },
      // })
    }
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.PHOTOBOOTH_ANALYTICS, PHOTOBOOTH_ANALYTICS)
    takeEvery(actions.UPLOAD_IMAGE, UPLOAD_IMAGE)
  ])
}
