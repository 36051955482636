import React from 'react'
import { Row, Col, Button } from 'antd'
// import { LeftOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/toonphotobooth/actions'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })

@connect(mapStateToProps)
class Showcase extends React.Component {
  retake = () => {
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 1,
        imageSrc: '',
      },
    })
  }

  // goNext = () => {
  //   const {
  //     dispatch,
  //     toonphotobooth: { currentStep },
  //   } = this.props
  //   dispatch({
  //     type: actions.SET_STATE,
  //     payload: {
  //       currentStep: currentStep + 1,
  //     },
  //   })
  //   dispatch({
  //     type: actions.toonphotobooth_ANALYTICS
  //   })
  // }



  goNext = () => {
    const {
      dispatch,
      toonphotobooth: { currentStep,imageblob },
    } = this.props

    dispatch({
      type: actions.UPLOAD_IMAGE,
      payload: {
        imageString: imageblob
      },
    })

    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
    // dispatch({
    //   type: actions.toonphotobooth_ANALYTICS
    // })
  }
  

  render() {
    const {
      toonphotobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row className="fullHeightScreen">
          <Col span={18} className="text-left">
            <img src={imageSrc} alt="newImage" style={{ height: '100%', borderRadius: '20px 0 0 20px' }} />
          </Col>
          <Col span={6} className="text-center">
            <Row className="fullHeightScreen" justify="space-around" align="middle">
              <Col span={24} className="text-center">
                <Button shape="circle" onClick={this.goNext} size="large">
                  Toonify Me
                </Button>
                <br />
                <br />
                <h4 className="white-text">OR</h4>
                <br />
                <Button shape="circle" onClick={this.retake} size="large" ghost>
                  Retake
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Looking Awesome!</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Retake
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center" />
        </Row> */}
      </>
    )
  }
}

export default Showcase
