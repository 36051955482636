import React from 'react'
import { Row, Col, Button, Upload, Tooltip, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop';
import { connect } from 'react-redux'
import actions from 'redux/virtual/toonphotobooth/actions'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus */
@connect(mapStateToProps)
class UploadImage extends React.Component {

  beforeUpload = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png']
    const currentMaxSize = 10
    const isFileValid = allowedTypes.includes(file.type);
    if (!isFileValid) {
      message.error(`Invalid Format. Only jpg/png files are allowed`);
    }
    const isValidFileSize = file.size / 1024 / 1024 < currentMaxSize; // file size should be less than 655kb
    if (!isValidFileSize) {
      message.error(`File too large. Kindly upload the file that is less than ${currentMaxSize}MB`);
    }
    return isFileValid && isValidFileSize;
  }

  fileUpload = (thefile) => {
    console.log("inside upload")
    console.log(thefile.file.originFileObj)
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.UPLOAD_IMAGE,
      payload: {
        imageString: thefile.file.originFileObj
      },
    })

    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 5,
        imageProcessing: true
      },
    })
  }

  render() {
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center fullHeightScreen">
          <Col span={6} />
          <Col span={12} className="text-center">
            <h4 className="white-text">Upload your photograph to Toonify</h4>
            <p className="white-text">Make sure you select a photograph with good lighting &amp; and a clean background</p>
            <br />
            <br />
            <ImgCrop rotate>
              <Upload 
                beforeUpload={this.beforeUpload}
                onChange={this.fileUpload}
              >
                <Tooltip title="Upload Your Entry">
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Tooltip>
              </Upload>
            </ImgCrop>
          </Col>
          <Col span={6} />
        </Row>
      </>
    )
  }
}

export default UploadImage
