import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import actions from 'redux/virtual/toonphotobooth/actions'
import Webcam from 'react-webcam'

const mapStateToProps = ({ toonphotobooth }) => ({ toonphotobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  capture = () => {
    const {
      dispatch
    } = this.props
    const { countdown } = this.state
    this.setState({ startCountdown: true })
    setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 })
    }, 1000)
    setTimeout(() => {
      const imageSrc = this.webcam.getScreenshot()
      const imgBlob = this.dataURLtoFile(imageSrc,"photobooth.png")
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentStep: 4,
          imageSrc,
          webcamLoading: true,
          imageblob: imgBlob,
          imageProcessing: true
        },
      })
    }, countdown * 1000 + 100)
  }

  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }

  render() {
    const {
      toonphotobooth: { webcamLoading },
    } = this.props
    const { countdown, startCountdown } = this.state
    const videoConstraints = {
      width: 512,
      height: 512,
      facingMode: 'user',
    }
    return (
      <>
        <Row className="fullHeightScreen">
          <Col span={18} className="text-left">
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="countdown">{countdown}</h2> : ''}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{ height: '100%', borderRadius: "0 20px 20px 0" }}
              ref={this.setRef}
              minScreenshotHeight={512}
              minScreenshotWidth={512}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
          <Col span={6} className="text-center">
            <Row className="fullHeightScreen" justify="space-around" align="middle">
              <Col span={24} className="text-center" style={{padding: '10px'}}>
                <Button
                  type="primary"
                  shape="circle"
                  onClick={this.capture}
                  icon={<CameraOutlined />}
                  size="large"
                />
                <br />
                <br />
                <h5 className="white-text">Tap to snap</h5>
                <br />
                <p className="white-text">Make sure that your face is properly lit and the background is clean</p>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Take a selfie</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button
              type="primary"
              shape="circle"
              onClick={this.capture}
              icon={<CameraOutlined />}
              size="large"
            />
          </Col>
        </Row> */}
      </>
    )
  }
}

export default Capture
